import React from 'react'
import styles from './Steps.module.scss'
import { motion } from "framer-motion"
import image_1 from '../../image/Steps/img_1.webp'
import image_3 from '../../image/Steps/img_3.webp'
import image_4 from '../../image/Steps/img_4.webp'
import image_5 from '../../image/Steps/img_5.webp'


export const Steps = ({isOpen}) => {

    const titleAnimation = {
        hidden: {
            y: 200,
            opacity: 0,
        },
    
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: .3 }
        }
    }
    
    const boxAnimation = {
        hidden: {
            y: 500,
            opacity: 0,
        },
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: .6}
        } 
    }

    return (
        <motion.section 
            className={styles.main} 
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.05, once: true}}
            >
            <div className={styles.container}>
            <motion.h2 className={styles.title} variants={titleAnimation}>Варианты материала<span> лестницы</span></motion.h2>
            <motion.ul className={styles.list} variants={boxAnimation}>
                        <li className={styles.item}>
                            <img className={styles.icon} src={image_1} alt='дерево'/>
                            <h3 className={styles.item_title}>Сосна</h3>
                        </li>
                        <li className={styles.item}>
                            <img className={styles.icon} src={image_3} alt='дерево'/>
                            <h3 className={styles.item_title}>Ясень</h3>
                        </li>
                        <li className={styles.item}>
                            <img className={styles.icon} src={image_4} alt='дерево'/>
                            <h3 className={styles.item_title}>Бук</h3>
                        </li>
                        <li className={styles.item}>
                            <img className={styles.icon} src={image_5} alt='дерево'/>
                            <h3 className={styles.item_title}>Дуб</h3>
                        </li>
                    </motion.ul>
                    <button className={styles.button} type='button' onClick={isOpen}>заказать обшивку</button>
            </div>   
        </motion.section>
    )
}