import React from 'react'
import styles from './Advantages.module.scss'
import { motion } from "framer-motion"

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .4}
    } 
}


export const Advantages = ({isOpen}) => {

    return (
        <motion.section 
        className={styles.main} 
        id='Advantages'
        initial='hidden'
        whileInView='visible'
        viewport={{amount: 0.1, once: true}}
        >
        <div className={styles.container}>
        <motion.h2 className={styles.title} variants={titleAnimation}>Почему <span> выбирают нас?</span></motion.h2>
        <motion.ul className={styles.list} variants={boxAnimation}>
                    <li className={styles.item}>
                        <p className={styles.number}>01</p>
                        <h3 className={styles.item_title}>Соблюдаем сроки</h3>
                        <p className={styles.item_text}>Никогда не срываем сроки указанные в договоре. Ценим время наших клиентов и достигнутые договоренности.</p>
                    </li>
                    <li className={styles.item}>
                        <p className={styles.number}>02</p>
                        <h3 className={styles.item_title}>Качество</h3>
                        <p className={styles.item_text}>Мы осуществляем контроль качества на каждом из этапов от проектирования и производства лестниц до монтажа и сдачи проекта, поэтому гарантируем качество наших изделий.</p>
                    </li>
                    <li className={styles.item}>
                        <p className={styles.number}>03</p>
                        <h3 className={styles.item_title}>Экологичность</h3>
                        <p className={styles.item_text}>При изготовлении лестниц и комплектующих используем только качественные и проверенные материалы. Дерево, металл, стекло, лако-покрасочные материалы.</p>
                    </li>
                    <li className={styles.item}>
                        <p className={styles.number}>04</p>
                        <h3 className={styles.item_title}>Профессионализм</h3>
                        <p className={styles.item_text}>Более чем 15 летний опыт в изготовлении и обшивке лестниц, обеспечивает профессиональное исполнение работ и качественный результат.</p>
                    </li>
                    <li className={styles.item}>
                        <p className={styles.number}>05</p>
                        <h3 className={styles.item_title}>Индивидуальность</h3>
                        <p className={styles.item_text}>Мы всегда стараемся уйти от типовых проектов, и сделать каждую нашу лестницу уникальной. Лестница, сделанная нами, многие годы будет украшением вашего дома.</p>
                    </li>
                    <li className={styles.item}>
                        <p className={styles.number}>06</p>
                        <h3 className={styles.item_title}>Доступные цены</h3>
                        <p className={styles.item_text}>Cобственное производство и отсутствие посредников позволяют нам держать цены на доступном для клиентов уровне.</p>
                    </li>
                </motion.ul>
        </div>   
    </motion.section>
    )
}