import React from 'react'
import styles from './Main.module.scss'
import { InitialPage } from '../../components/initialPage/InitialPage'
import {SliderAdvantages} from '../../components/SliderAdvantages/SliderAdvantages'
import { AboutUs } from '../../components/AboutUs/AboutUs'
import { Deadlines } from '../../components/Deadlines/Deadlines'
import {Practical} from '../../components/Practical/Practical'
import {Advantages} from '../../components/Advantages/Advantages'
import { Services } from '../../components/Services/Services'
import {VideoClip} from '../../components/VideoClip/VideoClip'
import {Portfolio} from '../../components/Portfolio/Portfolio'
import { Discount } from '../../components/Discount/Discount'

export const Main = ({isOpen, isImageOpen, popupOpen}) => {

    return (
        <section className={styles.main}>
            <InitialPage
                isOpen={isOpen}
            />
            <SliderAdvantages/>
            <AboutUs
                isOpen={isOpen}
            />
            <Services
                isOpen={isOpen}
            />
            <Advantages
                isOpen={isOpen}
            />
            <Portfolio
                isImageOpen={isImageOpen}
            />
            <VideoClip/>
            <SliderAdvantages/>
            <Discount
                popupOpen={popupOpen}
            />
            <Deadlines
                isOpen={isOpen}
            />
            <Practical/>
        </section>
    )
}