import React, { useCallback, useRef } from 'react'
import InputMask from "react-input-mask"
import emailjs from 'emailjs-com'
import styles from './Discount.module.scss'
import { FormValidation } from '../../hooks/FormValidation'

export const Discount = ({popupOpen}) => {

    const handleForm = FormValidation()
    //const [buttonDisabled, setButtonDisabled] = useState(true)
    const form = useRef()

    /*useEffect(() => {
        handleForm.isValid ? setButtonDisabled(false) : setButtonDisabled(true)
    }, [handleForm.isValid])*/

    const sendData = useCallback((evt) => {
        evt.preventDefault();
        console.log(handleForm)
            emailjs.sendForm('service_bujtqi8', 'template_qkh1wfc', form.current, 'hdfJqJdhKucKq7TXe')
            .then((result) => {
            }, (error) => {
                console.log(error.text);
            }); 
        handleForm.resetForm()
        popupOpen()
    }, [handleForm, popupOpen] )

    return (
        <section 
            className={styles.main}
            >
                <div className={styles.container}>
                    <h2 className={styles.title}>ЗАПОЛНИТЕ ФОРМУ, ЧТОБЫ УЗНАТЬ СТОИМОСТЬ ИЗГОТОВЛЕНИЯ ЛЕСТНИЦЫ</h2>
                    <p className={styles.text}>Наш инженер оперативно предоставит квалифицированную консультацию и рассчитает цену лестницы</p>
                    <form className={styles.form} onSubmit={sendData} ref={form}>
                        <div className={styles.form_row}>
                            <div className={styles.form_cell}>
                                <label className={styles.form_label}>Высота, мм</label>
                                <input
                                    className={styles.form_input}
                                    type='text' 
                                    name='visota'
                                    value={handleForm.values.this}
                                    onChange={handleForm.handleChange}
                                    aria-labelledby="высота" 
                                />
                            </div>
                            <div className={styles.form_cell}>
                                <label className={styles.form_label}>Длина проема, мм</label>
                                <input
                                    className={styles.form_input}
                                    type='text' 
                                    name='dlina'
                                    value={handleForm.values.this}
                                    onChange={handleForm.handleChange}
                                    aria-labelledby="длина"  
                                />
                            </div>
                            <div className={styles.form_cell}>
                                <label className={styles.form_label}>Ширина проема, мм</label>
                                <input
                                    className={styles.form_input}
                                    type='text' 
                                    name='shirina'
                                    value={handleForm.values.this}
                                    onChange={handleForm.handleChange}
                                    aria-labelledby="ширина"  
                                />
                            </div>
                        </div>
                        <div className={styles.form_row}>
                            <div className={styles.form_cell_2}>
                                <label className={styles.form_label}>Вид продукции</label>
                                <select className={styles.select} 
                                    name='vid'
                                    value={handleForm.values.this}
                                    onChange={handleForm.handleChange} 
                                    >
                                    <option></option>
                                    <option value="лестница целиком">Лестница целиком</option>
                                    <option value="Облицовка каркаса/бетона">Облицовка каркаса/бетона</option>
                                    <option value="Только ограждение">Только ограждение</option>
                                </select>
                            </div>
                            <div className={styles.form_cell_2}>
                                <label className={styles.form_label}>Основание лестницы</label>
                                <select className={styles.select}
                                name='karkas'
                                value={handleForm.values.this}
                                onChange={handleForm.handleChange} 
                                >
                                    <option></option>
                                    <option value="Деревянные косоуры/тетивы">Деревянные косоуры/тетивы</option>
                                    <option value="Металлический каркас">Металлический каркас </option>
                                    <option value="Бетонный марш">Бетонный марш</option>
                                    <option value="Консольная лестница на больцах">Консольная на больцах</option>
                                </select>
                            </div>
                        </div>
                        <div className={styles.form_row}>
                            <div className={styles.form_cell_2}>
                                <label className={styles.form_label}>Ограждение</label>
                                <select className={styles.select}
                                name='ograzhdenie'
                                value={handleForm.values.this}
                                onChange={handleForm.handleChange} 
                                >
                                    <option></option>
                                    <option value="Деревянное">Деревянное</option>
                                    <option value="Кованое">Кованое</option>
                                    <option value="Стекло">Стекло</option>
                                    <option value="Трубка">Трубка</option>
                                    <option value="Струна">Струна</option>
                                </select>
                            </div>
                            <div className={styles.form_cell_2}>
                                <label className={styles.form_label}>Материал ступеней</label>
                                <select className={styles.select}
                                name='stupeni'
                                value={handleForm.values.this}
                                onChange={handleForm.handleChange} 
                                >
                                    <option></option>
                                    <option value="Дуб">Дуб</option>
                                    <option value="Ясень">Ясень</option>
                                    <option value="Бук">Бук</option>
                                    <option value="Сосна">Сосна</option>
                                </select>
                            </div>
                        </div>
                        <div className={styles.form_row}>
                            <div className={styles.form_cell_2}>
                                <label className={styles.form_label}>Поворот</label>
                                <select className={styles.select}
                                name='povorot'
                                value={handleForm.values.this}
                                onChange={handleForm.handleChange} 
                                >
                                    <option></option>
                                    <option value="Прямая лестница">Прямая лестница</option>
                                    <option value="С поворотом на 90 град.">С поворотом на 90 град.</option>
                                    <option value="С поворотом на 180 град.">С поворотом на 180 град.</option>
                                    <option value="Винтовая">Винтовая</option>
                                </select>
                            </div>
                            <div className={styles.form_cell_2}>
                                <label className={styles.form_label}>Подступенники</label>
                                <select className={styles.select}
                                name='podstupenniki'
                                value={handleForm.values.this}
                                onChange={handleForm.handleChange} 
                                >
                                    <option></option>
                                    <option value="Да">Да</option>
                                    <option value="Нет">Нет</option>
                                </select>
                            </div>
                        </div>
                        <div className={styles.form_textarea}>
                            <div className={styles.form_colomn}>
                                <label className={styles.form_label}>Дополнительная информация</label>
                                <textarea
                                            className={styles.textarea}
                                            name='comment'
                                            value={handleForm.values.this}
                                            onChange={handleForm.handleChange}
                                            aria-labelledby="комментарий"
                                            />
                            </div>
                        </div>
                        <div className={styles.form_row}>
                            <div className={styles.form_cell_2}>
                                <label className={styles.form_label}>Ваше имя</label>
                                <input
                                        className={`${styles.select} ${handleForm.errors.name && styles.wrong}`}
                                        type='text' 
                                        name='name'
                                        minLength='2'
                                        value={handleForm.values.this}
                                        onChange={handleForm.handleChange} 
                                        aria-labelledby="имя"
                                        required/>
                            </div>
                            <div className={styles.form_cell_2}>
                                <label className={styles.form_label}>Телефон для связи</label>
                                <InputMask
                                        className={`${styles.select} ${handleForm.errors.phone && styles.wrong}`}
                                        type='tel' 
                                        name='phone'
                                        value={handleForm.values.this}
                                        onChange={handleForm.handleChange}
                                        aria-labelledby="телефон"  
                                        mask={'+7\\(999) 999-99-99'}  
                                        pattern='\+7\s?[\(][0-9]{3}[\)]{0,1}\s?\d{3}[-]{0,1}\d{2}[-]{0,1}\d{2}'
                                        required/>
                            </div>
                        </div>
                            <button className={styles.button_form}type='submit'>отправить</button>
                        </form>
                </div>
        </section>
        
    )
}