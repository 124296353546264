import kur_1 from '../image/Portfolio/Kurovskoe/img_1.webp'
import kur_2 from '../image/Portfolio/Kurovskoe/img_2.webp'
import kur_3 from '../image/Portfolio/Kurovskoe/img_3.webp'
import kur_4 from '../image/Portfolio/Kurovskoe/img_4.webp'
import kur_5 from '../image/Portfolio/Kurovskoe/img_5.webp'
import kur_6 from '../image/Portfolio/Kurovskoe/img_6.webp'
import kur_7 from '../image/Portfolio/Kurovskoe/img_7.webp'

import sal_1 from '../image/Portfolio/Salmychi/img_1.webp'
import sal_2 from '../image/Portfolio/Salmychi/img_2.webp'
import sal_3 from '../image/Portfolio/Salmychi/img_3.webp'
import sal_4 from '../image/Portfolio/Salmychi/img_4.webp'
import sal_5 from '../image/Portfolio/Salmychi/img_5.webp'
import sal_6 from '../image/Portfolio/Salmychi/img_6.webp'
import sal_7 from '../image/Portfolio/Salmychi/img_7.webp'
import sal_8 from '../image/Portfolio/Salmychi/img_8.webp'

import mod_1 from '../image/Portfolio/Modnaya/img_1.webp'
import mod_2 from '../image/Portfolio/Modnaya/img_2.webp'
import mod_3 from '../image/Portfolio/Modnaya/img_3.webp'
import mod_4 from '../image/Portfolio/Modnaya/img_4.webp'
import mod_5 from '../image/Portfolio/Modnaya/img_5.webp'
import mod_6 from '../image/Portfolio/Modnaya/img_6.webp'
import mod_7 from '../image/Portfolio/Modnaya/img_7.webp'
import mod_8 from '../image/Portfolio/Modnaya/img_8.webp'
import mod_9 from '../image/Portfolio/Modnaya/img_9.webp'
import mod_10 from '../image/Portfolio/Modnaya/img_10.webp'

import oze_1 from '../image/Portfolio/Ozernyj/img_1.webp'
import oze_2 from '../image/Portfolio/Ozernyj/img_2.webp'
import oze_3 from '../image/Portfolio/Ozernyj/img_3.webp'
import oze_4 from '../image/Portfolio/Ozernyj/img_4.webp'
import oze_5 from '../image/Portfolio/Ozernyj/img_5.webp'
import oze_6 from '../image/Portfolio/Ozernyj/img_6.webp'
import oze_7 from '../image/Portfolio/Ozernyj/img_7.webp'
import oze_8 from '../image/Portfolio/Ozernyj/img_8.webp'
import oze_9 from '../image/Portfolio/Ozernyj/img_9.webp'

import ist_1 from '../image/Portfolio/Istra/img_1.webp'
import ist_2 from '../image/Portfolio/Istra/img_2.webp'
import ist_3 from '../image/Portfolio/Istra/img_3.webp'
import ist_4 from '../image/Portfolio/Istra/img_4.webp'
import ist_5 from '../image/Portfolio/Istra/img_5.webp'
import ist_6 from '../image/Portfolio/Istra/img_6.webp'
import ist_7 from '../image/Portfolio/Istra/img_7.webp'
import ist_8 from '../image/Portfolio/Istra/img_8.webp'
import ist_9 from '../image/Portfolio/Istra/img_9.webp'
import ist_10 from '../image/Portfolio/Istra/img_10.webp'

import pri_1 from '../image/Portfolio/Primavera/img_1.webp'
import pri_2 from '../image/Portfolio/Primavera/img_2.webp'
import pri_3 from '../image/Portfolio/Primavera/img_3.webp'
import pri_4 from '../image/Portfolio/Primavera/img_4.webp'
import pri_5 from '../image/Portfolio/Primavera/img_5.webp'
import pri_6 from '../image/Portfolio/Primavera/img_6.webp'
import pri_7 from '../image/Portfolio/Primavera/img_7.webp'
import pri_8 from '../image/Portfolio/Primavera/img_8.webp'
import pri_9 from '../image/Portfolio/Primavera/img_9.webp'
import pri_10 from '../image/Portfolio/Primavera/img_10.webp'


export const arrKurovskoe = [{image: kur_1, title: 'Деревянная лестница из сосны', price: '250 000 ₽'}, { image: kur_2, title: 'Деревянная лестница из сосны'}, { image: kur_3, title: 'Деревянная лестница из сосны'}, {image: kur_4, title: 'Деревянная лестница из сосны'}, { image: kur_5, title: 'Деревянная лестница из сосны'}, { image: kur_6, title: 'Деревянная лестница из сосны'}, {image: kur_7, title: 'Деревянная лестница из сосны'},];

export const arrSalmychi = [{image: sal_1, title: 'Обшив металического каркаса', price: '320 000 ₽'}, { image: sal_2, title: 'Обшив металического каркаса'}, { image: sal_3, title: 'Обшив металического каркаса'}, {image: sal_4, title: 'Обшив металического каркаса'}, { image: sal_5, title: 'Обшив металического каркаса'}, { image: sal_6, title: 'Обшив металического каркаса'}, {image: sal_7, title: 'Обшив металического каркаса'}, {image: sal_8, title: 'Обшив металического каркаса'},];

export const arrModnaya = [{image: mod_1, title: 'Обшив металического каркаса', price: '400 000 ₽ (без стекла)'}, { image: mod_2, title: 'Обшив металического каркаса'}, { image: mod_3, title: 'Обшив металического каркаса'}, {image: mod_4, title: 'Обшив металического каркаса'}, { image: mod_5, title: 'Обшив металического каркаса'}, { image: mod_6, title: 'Обшив металического каркаса'}, {image: mod_7, title: 'Обшив металического каркаса'}, {image: mod_8, title: 'Обшив металического каркаса'}, {image: mod_9, title: 'Обшив металического каркаса'}, {image: mod_10, title: 'Обшив металического каркаса'},];

export const arrOzernyj = [{image: oze_1, title: 'Обшив металического каркаса', price: '350 000 ₽'}, { image: oze_2, title: 'Обшив металического каркаса'}, { image: oze_3, title: 'Обшив металического каркаса'}, {image: oze_4, title: 'Обшив металического каркаса'}, { image: oze_5, title: 'Обшив металического каркаса'}, { image: oze_6, title: 'Обшив металического каркаса'}, {image: oze_7, title: 'Обшив металического каркаса'}, {image: oze_8, title: 'Обшив металического каркаса'}, {image: oze_9, title: 'Обшив металического каркаса'},];

export const arrIstra = [{image: ist_1, title: 'Обшив бетонного каркаса', price: '360 000 ₽'}, { image: ist_2, title: 'Обшив бетонного каркаса'}, { image: ist_3, title: 'Обшив бетонного каркаса'}, {image: ist_4, title: 'Обшив бетонного каркаса'}, { image: ist_5, title: 'Обшив бетонного каркаса'}, { image: ist_6, title: 'Обшив бетонного каркаса'}, {image: ist_7, title: 'Обшив бетонного каркаса'}, {image: ist_8, title: 'Обшив бетонного каркаса'}, {image: ist_9, title: 'Обшив бетонного каркаса'}, {image: ist_10, title: 'Обшив бетонного каркаса'},];

export const arrPrimavera = [{image: pri_1, title: 'Обшив бетонного каркаса(2 этажа)', price: '750 000 ₽'}, { image: pri_2, title: 'Обшив бетонного каркаса'}, { image: pri_3, title: 'Обшив бетонного каркаса'}, {image: pri_4, title: 'Обшив бетонного каркаса'}, { image: pri_5, title: 'Обшив бетонного каркаса'}, { image: pri_6, title: 'Обшив бетонного каркаса'}, {image: pri_7, title: 'Обшив бетонного каркаса'}, {image: pri_8, title: 'Обшив бетонного каркаса'}, {image: pri_9, title: 'Обшив бетонного каркаса'}, {image: pri_10, title: 'Обшив бетонного каркаса'},];


export const arrPortfolio = [arrKurovskoe, arrSalmychi, arrModnaya, arrOzernyj, arrIstra, arrPrimavera,];

export const arrAdvantages = [{text:'Собственное ПРОИЗВОДСТВО'}, {text:'Персональный ПОДХОД'}, {text:'Гарантия КАЧЕСТВА'}, {text:'Демократичные ЦЕНЫ'}, {text:'система СКИДОК'}, {text:'натуральные МАТЕРИАЛЫ'},];

export const arrVideo =[{video: 'https://youtu.be/Osz1HcHCoe4'}, {video: 'https://youtu.be/IRFr3gFQ6mc'}, {video: 'https://youtu.be/48bWiw8Gr9Q'}, {video: 'https://youtu.be/h7uMmj2EmuQ'},  {video: 'https://youtu.be/M0tmwVGyU68'}, {video: 'https://youtu.be/pkwMnx-BzP8'},] 