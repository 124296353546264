import React from 'react'
import { motion } from "framer-motion"
import styles from './AboutUs.module.scss'
import image from '../../image/AboutUs/img.webp'

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}

const textAnimation = {
    hidden: {
        x: 500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

export const AboutUs = () => {

    return (
        <motion.section 
            className={styles.main} 
            id='AboutUs'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}             
            >
            <div className={styles.container}>
                    <motion.img className={styles.image} src={image} alt='металл' variants={imageAnimation}/>
                    <motion.div className={styles.box_info} variants={textAnimation}>
                        <h2 className={styles.title}>О нашей <span>компании</span></h2>
                        <p className={styles.text}>&#8195;Компания "Мир Лестниц" профессионально занимается проектированием, производством и установкой лестниц и любых комплектующих по всей России, более 15 лет.</p>
                        <p className={styles.text}>&#8195;Лестницы от компании "Мир Лестниц" — это, прежде всего качество, потому что они изготавливаются  из натурального дерева, способного служить многие годы. Именно дерево способно хранить тепло солнца и человеческих рук, его натуральность и естественность несут в себе неповторимую притягательность и эстетичность.</p>
                        <p className={styles.text}>&#8195;Изящество и красота лестницы, как ключевого элемента домашнего интерьера, при виде которого замирает сердце, заставит Ваших гостей приходить в изумление и даже вызовет легкую, доброжелательную зависть. Имея у себя дома шикарную лестницу от нашей компании, Ваш вкус и авторитет будет просто неоспорим.</p>
                        <p className={styles.text}>&#8195; Производство и изготовление лестницы занимает от двух недель в зависимости от сложности заказа. Чтобы изготовление лестниц стало действительно качественным, мы используем только первоклассные и натуральные материалы, среди которых, в частности, кавказский дуб, бук, ясень, сосна, береза, лиственница.</p>
                        <p className={styles.text}>&#8195;Лестница от компании «Мир Лестниц» — это 50 % достоинства и красоты Вашего дома! Чтобы стать действительно ценным приобретением и радовать Вас долгие годы, лестница обязательно должна быть профессионально спроектирована, тщательно изготовлена и правильно установлена. Это три ключевых этапа на пути создания качественной лестницы. Мы всегда и все стараемся делать максимально эффективно, чтобы качественное производство и изготовление лестниц для дома дарило  радость нашим клиентам и их гостям!</p>
                    </motion.div>
            </div>
        </motion.section>
        
        
    )
}