import React from 'react'
import styles from './Deadlines.module.scss'
import { motion } from "framer-motion"
import image_1 from '../../image/Deadlines/img_1.svg'
import image_2 from '../../image/Deadlines/img_2.svg'
import image_3 from '../../image/Deadlines/img_3.svg'
import image_4 from '../../image/Deadlines/img_4.svg'
import image_5 from '../../image/Deadlines/img_5.svg'
import image_7 from '../../image/Deadlines/img_7.svg'
import image_8 from '../../image/Deadlines/img_8.svg'

export const Deadlines = ({isOpen}) => {

    const titleAnimation = {
        hidden: {
            y: 200,
            opacity: 0,
        },
    
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: .3 }
        }
    }
    
    const boxAnimation = {
        hidden: {
            y: 500,
            opacity: 0,
        },
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: .6}
        } 
    }

    return (
        <motion.section 
            className={styles.main} 
            id='Order'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.05, once: true}}
            >
            <div className={styles.container}>
            <motion.h2 className={styles.title} variants={titleAnimation}>Порядок<span> оформления заказа</span></motion.h2>
            <motion.ul className={styles.list} variants={boxAnimation}>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_1} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>Заявка</h3>
                            <p className={styles.item_text}>Вы оставляете электронную заявку на сайте, электронной почте, мессенджере или звоните нам по телефону.</p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_2} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>Консультация</h3>
                            <p className={styles.item_text}> После отправки заявки с вами свяжется менеджер с целью уточнить ваш заказ и обговорить все детали по оплате и доставке.</p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_3} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>Замер</h3>
                            <p className={styles.item_text}>При необходимости, на объект выезжает специалист нашей компании для проведения точных замеров.</p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_4} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>Проект</h3>
                            <p className={styles.item_text}>Готовим рабочий проект, согласовываем смету и стоимость работ, подписываем договор.</p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_5} alt='кошелек'/>
                            </div>
                            <h3 className={styles.item_title}>Оплата</h3>
                            <p className={styles.item_text}>Вы оплачиваете пятьдесят процентов оговоренной стоимости заказа любым удобным для вас способом. </p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_8} alt='кошелек'/>
                            </div>
                            <h3 className={styles.item_title}>Производство</h3>
                            <p className={styles.item_text}>После поступления денежных средств на наш счет мы отдаем ваш заказ в производство. </p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_7} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>Доставка</h3>
                            <p className={styles.item_text}>В назначенные дату и время осуществляется доставка оплаченного вами заказа по заявленному адресу.</p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_8} alt='кошелек'/>
                            </div>
                            <h3 className={styles.item_title}>Монтаж</h3>
                            <p className={styles.item_text}>Самый дешевый вариант - сразу сделать хорошо! Мы делаем свою работу так, чтобы исключить проблем в будущем.</p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_5} alt='кошелек'/>
                            </div>
                            <h3 className={styles.item_title}>Оплата</h3>
                            <p className={styles.item_text}>Вы оплачиваете остаток стоимости вашего заказа. Мы предоставляем гарантию на проведенные нами работы.</p>
                        </li>
                    </motion.ul>
                    <button className={styles.button} type='button' onClick={isOpen}>заказать лестницу</button>
            </div>   
        </motion.section>
    )
}
