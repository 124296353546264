import React from 'react'
import { motion } from "framer-motion"
import styles from './Services.module.scss'
import icon_1 from '../../image/Practical/img_4.webp'
import icon_2 from '../../image/Practical/img_2.webp'
import icon_3 from '../../image/Practical/img_3.webp'
import { Steps } from '../Steps/Steps'
import { Fences } from '../Fences/Fences'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 500,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .9}
    } 
}

export const Services = ({isOpen}) => {

    return (
        <motion.section 
            className={styles.main} 
            id='Services'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
                <div className={styles.box}>
                    <motion.h2 className={styles.title} variants={titleAnimation}>Мы делаем и обшиваем лестницы на каркасах</motion.h2>
                    <motion.ul className={styles.list} variants={boxAnimation}>
                        <li className={styles.item}>
                            <h3 className={styles.item_title}>деревянныЙ</h3>
                            <img className={styles.item_image}  src={icon_1} alt='дом'/>
                            <button className={styles.button} type='button' onClick={isOpen}>ЗАКАЗАТЬ</button>
                        </li>
                        <li className={styles.item}>
                            <h3 className={styles.item_title}>металлический</h3>
                            <img className={styles.item_image}  src={icon_2} alt='дом'/>
                            <button className={styles.button} type='button' onClick={isOpen}>ЗАКАЗАТЬ</button>
                        </li>
                        <li className={styles.item}>
                            <h3 className={styles.item_title}>бетонный</h3>
                            <img className={styles.item_image}  src={icon_3} alt='дом'/>
                            <button className={styles.button} type='button' onClick={isOpen}>ЗАКАЗАТЬ</button>
                        </li>
                    </motion.ul>
                </div>
                <div className={styles.type_box}>
                    <Steps
                        isOpen={isOpen}
                    />
                </div>
                <div className={styles.box}>
                    <Fences
                        isOpen={isOpen}
                    />
                </div>
        </motion.section>
        
    )
}